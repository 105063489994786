<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="isNew" color="green" dark class="mb-2" v-bind="attrs" v-on="on" @click="fetchAdd"> {{ $t("Add Document Type") }}</v-btn>
      <v-btn v-else v-bind="attrs" v-on="on" color="blue" icon @click="fetchData">
        <v-icon class="ml-1">mdi-pen</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-text class="pt-5">
        <v-row>
          <v-col md="12">
            <v-autocomplete :label="$t('Document Type')" outlined dense color="#757575" item-text="label"
              item-value="value" :items="types" v-model="documentData.type"></v-autocomplete>
          </v-col>
          <v-col md="12">
            <v-text-field :label="$t('Document Name')" outlined dense color="#757575"
              v-model="documentData.name"></v-text-field>
          </v-col>
          <v-col md="12">
            <v-text-field :label="$t('Current Number')" outlined dense color="#757575"
              v-model="documentData.serial"></v-text-field>
          </v-col>
          <v-col md="12">
            <AutoCompleteField :label="$t('hr.loan.cash')" :cash="1" outlined dense no-filter v-model="documentData.cash_account_id"
              endPoint="/accounting/account/auto-complete" returnObject />
          </v-col>
          <v-col md="12">
            <v-autocomplete :label="$t('Tax Code')" outlined dense color="#757575" item-text="code" item-value="id"
                :items="taxes" v-model="documentData.tax_id"></v-autocomplete>
          </v-col>
          <v-col md="6">
            <AutoCompleteField :label="$t('Tax In')" outlined dense no-filter v-model="documentData.tax_in_account_id"
              endPoint="/accounting/account/auto-complete" returnObject />
          </v-col>
          <v-col md="6">
            <AutoCompleteField :label="$t('Tax Out')" outlined dense no-filter v-model="documentData.tax_out_account_id"
              endPoint="/accounting/account/auto-complete" returnObject />
          </v-col>
          <template>
            <v-col class="justify-center py-5" md="12">
              <h3 style="color: var(--bg-color--font);">{{ $t('Document fields') }}</h3>
            </v-col>
            <v-row v-if="documentData.table_view.length">
              <template v-for="(item, index) in documentData.table_view">
                <v-checkbox :dark="$store.state.isDarkMode" :key="index" :label="item.name" class="mx-4 mb-4"
                  v-model="item.value" hide-details :true-value="1" :false-value="0"></v-checkbox>
              </template>
            </v-row>
          </template>
        </v-row>
        <v-card-actions class="py-5 d-flex justify-end">
          <v-btn depressed color="blue-grey" class="white--text" @click="createdocument" :loading="submitting">{{ isNew ?
            $t("add") : $t("edit") }}</v-btn>
        </v-card-actions>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
<script>
import axios from "axios";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";
export default {
  components: { AutoCompleteField, },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    document: Object,
  },
  data() {
    return {
      dialog: false,
      types: [
        {
          label: "يومية صندوق",
          value: "DAILY",
        },
      ],
      documentData: {
        name: null,
        type: null,
        serial: null,
        cash_account_id: null,

        tax_in_account_id: null,
        tax_out_account_id: null,
        tax_id: null,
        table_view: [],
      },
      taxes: [],
      submitting: false,
    };
  },
  methods: {
    async createdocument() {
      try {
        this.submitting = true;
        let payload = {};
        this.documentData.cash_account_id = this.documentData.cash_account_id?.id;
        this.documentData.tax_in_account_id = this.documentData.tax_in_account_id?.id;
        this.documentData.tax_out_account_id = this.documentData.tax_out_account_id?.id;
        payload = { ...this.documentData };
        if (this.isNew) {
          await axios.post("/accounting/voucher-type", payload);
        } else {
          await axios.put(
            `/accounting/voucher-type/${this.document.id}`,
            payload
          );
        }
        this.dialog = false;
        this.$emit("done");
      } finally {
        this.submitting = false;
      }
    },
    async fetchAdd() {
      let params = {
        paginated: 'false',
      };
      if(this.returnAbility('tax:index')){
        let res = await axios.get(`/accounting/tax`, { params });
        this.taxes = res.data.data;
      }
      let result = await axios.get(`/inventories/table-view/index?type=Voucher`);
      this.documentData.table_view = result.data.data;
    },
    async fetchData() {
      let params = {
        paginated: 'false',
      };
      if(this.returnAbility('tax:index')){
        let res = await axios.get(`/accounting/tax`, { params });
        this.taxes = res.data.data;
      }
      let res = await axios.get(`/accounting/voucher-type/${this.document.id}`);
      this.document = res.data.data;
      this.documentData = { ...this.document };
      if (this.document.cash_account) {
        this.documentData.cash_account_id = this.document.cash_account;
      }
      if (this.document.tax_in_account) {
        this.documentData.tax_in_account_id = this.document.tax_in_account;
      }
      if (this.document.tax_out_acccount) {
        this.documentData.tax_out_account_id = this.document.tax_out_acccount;
      }
    },
  },
};
</script>
<style></style>
  
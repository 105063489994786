<template>
    <div style="margin: 25px">
      <createDocument @done="getAll" />
      <v-data-table :dark="$store.state.isDarkMode"
        :headers="headers"
        :loading="loading"
        :items="items"
        class="elevation-1"
        hide-default-footer
        :items-per-page="10"
      >
        <template v-slot:item.actions="{ item }">
          <deleteDocument :id="item.id" @record-deleted="getAll" />
          <createDocument @done="getAll" :isNew="false" :document="item" />
        </template>
      </v-data-table>
      <v-pagination
        v-if="!loading && items.length"
        @input="getAll"
        v-model="page"
        :length="Math.ceil(total / 10)"
      ></v-pagination>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import createDocument from "./createDocument.vue";
  import deleteDocument from './deleteDocument.vue';
import i18n from "../../../../i18n";
  export default {
    components: { createDocument, deleteDocument },
    data() {
      return {
        loading: false,
        page: 1,
        total: 1,
        types: [
          {
            label: "يومية صندوق",
            value: "DAILY",
          },
        ],
        headers: [
          { text: i18n.t('Document Name'), value: "name" },
          { text: i18n.t('Document Type'), value: "type" },
          { text: "", value: "actions" },
        ],
        items: [],
      };
    },
    methods: {
      async getAll() {
        try {
          this.loading = true;
          const res = await axios.get("/accounting/voucher-type", {
            params: {
              size: 10,
              page: this.page,
            },
          });
          this.items = res.data.data.map((el) => ({
            ...el,
           
          }));
          this.total = res.data.meta.total;
        } finally {
          this.loading = false;
        }
      },
    },
    created() {
      this.getAll();
    },
  };
  </script>
  
  <style scoped></style>
  